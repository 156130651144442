
import { IonApp, IonModal, IonButton } from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import MainAudioPlayer from "./components/MainAudioPlayer.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonModal,
    IonButton,
    MainAudioPlayer,
  },
  setup() {
    const showPopup = ref(false);

    onMounted(() => {
      showPopup.value = true;
    });

    const redirectToUpdate = () => {
      window.open("https://campcourses.app.link/xn3SOb4QBQb", "_blank");
    };

    return { showPopup, redirectToUpdate };
  },
});
